.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  width: 300px;
  text-align: center;

  input[type="email"],
  input[type="password"],
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 18px;
  }

  .bottom-container {
    display: flex;
    flex-direction: row;
  }

  .icon-container {
    font-size: 25px;
    flex: 1;
  }

  .loginBtn {
    flex: 1;
    color: #000000;
    background-color: #45a049;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .empty {
    flex: 1;
  }
}
