.text-sphere {
  position: relative;
  top: -5%;
  right: 5%;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagcloud {
  display: inline-block;
  top: 0;
  left: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  letter-spacing: 0.0625em;
  font-size: 1.3em;
}

.tagcloud--item {
  color: #ffffff;
  text-shadow: 0 0 20px 0 lightyellow;
}

.tagcloud--item:hover {
  color: #ffd700;
}

.page-container {
  display: flex;
  flex-direction: row;
}

.leftSide, .rightSide {
  flex: 1;
}

.text-zone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skills-container {
  display: flex;
}

.skills-list {
  list-style: none;
  padding: 0;
}

.skills-list li {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-left: 20px;
  transition: color 0.3s ease;
}

.skills-list li:hover {
  font-weight: bold;
  color: #ffd700;
}

.skills-list:first-child {
  margin-right: 20px;
}

.ls {
  animation: bounceIn 1s backwards;
  animation-duration: 1s;
}

@for $i from 1 through 80 {
  .ls._#{$i} {
    animation-delay: #{$i * 0.25}s;
  }
}

@media screen and (max-width: 1200px) {
  .page-container {
    display: flex;
    flex-direction: column;
  }

  .leftSide, .rightSide {
    flex: 1;
  }

  .skills-container {
    margin-left: -5%;
  }

  .text-sphere {
    width: auto;
    height: auto;
    margin-right: 50%;
    margin-left: 50%;
    position: relative;
  }
}
