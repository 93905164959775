h6 {
  position: absolute;
  bottom: -20%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: none;
  display: block;
  box-sizing: border-box;
  font-size: 56px;
  font-family: 'Coolvetica', serif;
  color: #ffffff;

  &:before,
  &:after {
    content: '';
    font-family: 'La Belle Aurore', cursive;
    color: #000000;
    font-size: 20px;
    position: absolute;
    opacity: 0.6;
    margin-top: 30px;
  }

  &:before {
    content: '<h>';
    margin-left: -45px;
  }

  &:after {
    content: '</h>';
    margin-left: 15px;
    animation: fadeIn 1s 1.7s backwards;
  }
}

.map-container {
  position: relative;
  top: -30%;
  left: -1%;
  width: 100%;
  height: 110vh;
}

.reset-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px;
  background-color: #3498DB;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.marker-popup {
  position: absolute;
  bottom: -15px;
  right: 20%;
  color: #181818;
  font-size: 14px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;

  button {
    background-color: #3498DB;
    color: #fff;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.marker {
  animation: bounceIn;
  animation-duration: 2s;
  animation-fill-mode: backwards;
}

@for $i from 1 through 80 {
  .marker._#{$i} {
    animation-delay: #{$i * 0.05}s;
  }
}

@media screen and (max-width: 1200px) {
  .map-container {
    position: relative;
    top: -30%;
    left: -15%;
    width: 120%;
    height: auto;
  }

  .reset-button {
    right: 25px;
  }

  h6 {
    position: relative;
    margin: 0;
  }

  .marker-popup {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 80%;
    color: #181818;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
}
