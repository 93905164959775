h5 {
  color: #fff;
  margin-top: 50px;
  position: relative;

  &:before,
  &:after {
    content: '';
    font-family: 'La Belle Aurore', cursive;
    color: #000000;
    font-weight: lighter;
    font-size: 18px;
    position: absolute;
    opacity: 0.6;
  }

  &:before {
    content: '<p>';
    margin-top: -21px;
    margin-left: -20px;
  }

  &:after {
    content: '</p>';
    margin-top: -10px;
    margin-left: -20px;
    animation: fadeIn 1s 1.7s backwards;
  }
}

.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    width: 100%;
    position: initial;
    padding-bottom: 80%;
  }
}

.cube-spinner {
  animation: spincube 12s ease-in-out infinite;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 lightyellow;
  }

  .face1 { transform: translateZ(100px); color: #DB534C; }
  .face2 { transform: rotateY(90deg) translateZ(100px); color: #3776AB; }
  .face3 { transform: rotateY(90deg) rotateX(90deg) translateZ(100px); color: #0DB7ED; }
  .face4 { transform: rotateY(180deg) rotateZ(90deg) translateZ(100px); color: #61DAFB; }
  .face5 { transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px); color: #8CC84B; }
  .face6 { transform: rotateX(-90deg) translateZ(100px); color: #FC6D26; }
}

@keyframes spincube {
  from, to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% { transform: rotateY(-90deg) rotateZ(90deg); }
  33% { transform: rotateY(-90deg) rotateX(90deg); }
  50% { transform: rotateY(-180deg) rotateZ(90deg); }
  66% { transform: rotateY(-270deg) rotateX(90deg); }
  83% { transform: rotateX(90deg); }
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    width: 100%;
    position: initial;
    padding-bottom: 80%;
  }

  .text-zone {
    margin-left: 3%;
    margin-right: 3%;
  }
}