.position-container {
  flex: 1;
  text-align: left;
  margin-right: 5%;
  margin-top: 2%;
  padding: 20px;
  color: #ffffff;
}

.new-position-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Helvetica Neue", serif;
}

.position-data {
  display: flex;
  flex-direction: column;
  font-size: 15px;

  .top-position-data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .left-data,
    .right-data {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 48%;

      label {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #ffffff;

        input {
          padding: 8px;
          font-size: 14px;
          border: 1px solid #ccc;
          border-radius: 4px;
          color: #333;
          box-sizing: border-box;

          &:focus {
            outline: none;
            border-color: #007bff;
          }
        }
      }
    }
  }

  .bottom-position-data {
    text-align: center;
    font-size: 18px;
    margin: 10px 0;
    color: #333;
    font-weight: bold;
  }
}

.map {
  margin-top: 15px;
  .leaflet-container {
    height: 400px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

button {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;

  &:hover {
    background-color: #45a049;
  }
}

@media screen and (max-width: 768px) {
  .position-container {
    width: 90%;
    padding: 15px;
  }

  .top-position-data {
    flex-direction: column;

    .left-data,
    .right-data {
      width: 100%;
    }
  }

  .leaflet-container {
    height: 300px;
  }
}
