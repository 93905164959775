.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica', serif;
    font-weight: 400;

    &:before {
      content: '<h>';
      font-family: 'La Belle Aurore', cursive;
      color: #000000;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '</h>';
      font-family: 'La Belle Aurore', cursive;
      color: #000000;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #000000;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #cccccc;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #cccccc;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #cccccc;
      color: #333;
    }
  }

}

.right-zone {
  position: absolute;
  text-align: right;
  top: 15%;
  right: 2%;

  img {
    max-width: 50%;
    height: auto;
  }

  h3 {
    font-family: "Helvetica Neue", serif;
    color: #fff;
    font-size: 35px;
    line-height: 50px;
    text-align: right;
    margin-right: 17%;
    font-weight: 300;
  }
}



@media screen and (max-width: 1200px) {
  .tags {
    display: none;
  }

  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      box-sizing: border-box;
      padding: 10px 10px 10px 25px;

      .flat-button {
        float: none;
        display: block;
        margin: 20px auto 0 auto;
        width: 124px;
      }
    }

    h1:before,
    h1:after {
      display: none;
    }
  }

  .right-zone {
    position: relative;
    margin-top: 8%;

    img {
      max-width: 100%;
      margin-left: 3%;
      margin-bottom: 10px;
    }

    h3 {
      text-align: center;
      margin: 10px 0 0;
    }
  }
}