.dashboard {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1200px) {
  .dashboard {
    display: flex;
    flex-direction: column;
  }
}
