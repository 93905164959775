.new-project-container {
  flex: 1;
  align-self: center;
  margin-left: 200px;
}

.new-project-title {
  margin-left: 150px;
  font-size: 30px;
  font-family: "Helvetica Neue", serif;
  font-weight: bold;
  color: #ffffff;
}

.new-project-form {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form {
  p {
    margin-bottom: 15px;

    input,
    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    textarea {
      resize: vertical;
    }

    input[type="file"] {
      padding: 0;
    }
  }

  textarea {
    height: 80px;
  }

  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    align-self: center;
    align-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .new-project-container {
    flex: 1;
    margin: 0;
    align-self: center;
  }

  .new-project-title {
    margin-left: 0;
    text-align: center;
  }

  .new-project-form {
    width: auto;
  }
}
